import Vue from 'vue'
import App from './App.vue'
import './common/index.css'
import axios from "axios"
import router from './router/router.js'
import Quill from "quill";
// 富文本显示样式
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.config.productionTip = false

axios.defaults.baseURL = 'https://cnishn.com/api/official-sys'
axios.defaults.withCredentials = true
Vue.prototype.$http = axios

//请求设置
axios.interceptors.request.use(config => {
    config.headers.Authorization = window.sessionStorage.getItem("tokenHead") + " " + window.sessionStorage.getItem("token")
    return config
})


//响应设置
axios.interceptors.response.use(response => {

    let {message, status} = response.data

    return response
}, error => {

    //如果响应代码为401,token超时无效
    if (error.response && error.response.status === 401) {
        //清楚记录的path
        window.sessionStorage.removeItem("activePath")
        router.push('/')
    }
    return Promise.reject(error)
})

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
