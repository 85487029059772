import Vue from "vue"
import VueRouter from "vue-router"

//导入的页面
const HomePage = () => import(/* webpackChunkName: "HomePage_home" */ '@/View/home/HomePage')
const home = () => import(/* webpackChunkName: "HomePage_home" */ '@/View/home/home')
const notFount = () => import(/* webpackChunkName: "HomePage_home"*/ "@/View/404")

//导入contact页面
const OnlineMessage = () => import(/* webpackChunkName: "OnlineMessage" */ '@/View/contact/OnlineMessage')
const ContactUs = () => import(/* webpackChunkName: "OnlineMessage" */ '@/View/contact/ContactUs')

//导入module页面
const StandardizedHosting = () => import(/* webpackChunkName: "Standard" */ '@/View/module/StandardizedHosting')
const StandardizationConsultation = () => import(/* webpackChunkName: "Standard" */ '@/View/module/StandardizationConsultation')
const StandardizedTraining = () => import(/* webpackChunkName: "Standard" */ '@/View/module/StandardizedTraining')
const ServiceDevelopmentPlatform = () => import(/* webpackChunkName: "Standard" */ '@/View/module/ServiceDevelopmentPlatform')
const Leader = () => import(/* webpackChunkName: "Standard" */ '@/View/module/Leader')


const GreenEvaluation = () => import(/* webpackChunkName: "Green" */ '@/View/module/GreenEvaluation')

const BaseIntroduction = () => import(/* webpackChunkName: "group1" */ '@/View/AboutUs/BaseIntroduction')
const AchievementsAndHonors = () => import(/* webpackChunkName: "group1" */ '@/View/AboutUs/AchievementsAndHonors')
const CooperativePartner = () => import(/* webpackChunkName: "group1" */ '@/View/AboutUs/CooperativePartner')


const NewsInformation = () => import(/* webpackChunkName: "group1" */ '@/View/news/NewsInformation')

//招聘模块
const RecruitmentMain = () => import(/* webpackChunkName: "group2" */ '@/View/recruitment/RecruitmentMain')
const RecruitmentContent = () => import(/* webpackChunkName: "group2" */ '@/View/recruitment/RecruitmentContent')


//基地动态
const baseDynamics = () => import(/* webpackChunkName: "group2" */ '@/View/base/baseDynamics')

//服务案例
const ServicesCase = () => import(/* webpackChunkName: "group3" */ '@/View/servicesCase/ServicesCase')
const Content = () => import(/* webpackChunkName: "group3" */ '@/View/Content')


import login from "@/View/login";


Vue.use(VueRouter)

const routes = [
    {path: '/login', component: login},
    {
        path: '/', component: HomePage,

        children: [
            {path: '/', component: home},
            {path: '/OnlineMessage', component: OnlineMessage},
            {path: '/ContactUs', component: ContactUs},
            //模块导入
            {path: '/module/ServiceDevelopmentPlatform', component: ServiceDevelopmentPlatform},
            {path: '/module/StandardizedHosting', component: StandardizedHosting},
            {path: '/module/StandardizationConsultation', component: StandardizationConsultation},
            {path: '/module/StandardizedTraining', component: StandardizedTraining},
            {path: '/module/GreenEvaluation', component: GreenEvaluation},
            {path: '/module/Leader', component: Leader},

            {path: '/BaseIntroduction', component: BaseIntroduction},
            {path: '/AchievementsAndHonors', component: AchievementsAndHonors},
            {path: '/CooperativePartner', component: CooperativePartner},
            {path: '/News/NewsInformation', component: NewsInformation},
            {path: '/News/Content', name: "Content", component: Content},
            //招聘模块
            {path: '/recruitment/RecruitmentMain', component: RecruitmentMain},
            {path: '/recruitment/RecruitmentContent', component: RecruitmentContent},

            //基地动态
            {path: '/base/baseDynamics', component: baseDynamics},

            //服务案例
            {path: '/servicesCase/servicesCase', component: ServicesCase},
            {path: '/servicesCase/Content', name: "Content", component: Content},

            {path: "*", component: notFount}

        ],
    },


]

const router = new VueRouter({
    base: '/',
    mode: 'history',
    routes: routes,
})

export default router

