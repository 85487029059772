<template>
  <div class="page-login">
    <div class="box">
      <el-form label-position="top" class="form" :model="form" :rules="rules" ref="form" size="medium">
        <el-form-item label="用户名" prop="username">
          <el-input
              v-model="form.username"
              placeholder="请输入用户名"
              maxlength="20"
              autocomplete="off"
          />
        </el-form-item>

        <el-form-item label="密码" prop="password">
          <el-input
              v-model="form.password"
              type="password"
              placeholder="请输入密码"
              maxlength="20"
              autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="验证码" prop="password">
          <el-input
              class="VerificationCode-input"
              v-model="form.VerificationCode"
              type="text"
              placeholder="请输入验证码"
              maxlength="4"
              autocomplete="off"
          />
          <div class="VerificationCode"><img :src="VerificationCodeUrl" alt="" @click="VerificationCodeChange"></div>
        </el-form-item>
      </el-form>
      <el-button round size="small" class="submit-btn" @click="onsubmit('form')">登录</el-button>
    </div>
  </div>
</template>

<script>

export default {
  data: function () {
    var validateUsername = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('用户名不能为空'));
      } else {
        var reg = /^\w+$/g
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error("用户名不能是-或中文"))
        }
      }
    }

    var validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error("密码不能为空"))
      } else {
        var reg = /^\w+$/g
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("密码不能是-或中文"))
        }
      }
    }
    return {
      form: {
        username: '',
        password: '',
      },
      VerificationCodeUrl:'http://192.168.1.112:8080/hello',
      //表单验证规则
      rules: {
        username: [
          {validator: validateUsername, trigger: 'blur'}
        ],
        password: [
          {validator: validatePassword, trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    /**
     *
     *  表单提交
     **/
    onsubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http.post("/news/page", {currentPage: 1, currentPageSize: 2}).then(({data}) => {
            console.log(data)
          })
        } else {
          console.log("表单验证不通过")

        }
      });
    },
    /**
     *
     *  验证码改变事件
     **/
    VerificationCodeChange(){
      this.VerificationCodeUrl=this.VerificationCodeUrl+"?"+new Date().getTime();
    }

  }
}
</script>

<style scoped>
.page-login {
  height: 100vh;
  width: 100vw;
  position: relative;
  background-color: #2f3447;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 500px;
  position: absolute;
  left: calc(50% - 250px);
  top: calc(50% - 250px);
}

.el-form {
  width: 300px;
  border-radius: 3px;
}

.el-form-item {
  margin-bottom: 20px;
}

/deep/ .el-input .el-input__inner {
  border: 0;
  border-bottom: 0.5px solid #999;
  border-radius: 0;
  padding: 0 5px;
  background-color: transparent;
  color: #ccc;
  transition: border-color 0.3s;
  position: relative;
}


/deep/ .el-input__inner {
  -webkit-box-shadow: 0 0 0 1000px #2F3447 inset;
  -webkit-text-fill-color: #ffffff !important;
}


.submit-btn {
  margin-top: 40px;
  padding: 9px 40px;
  color: #000;
}
.VerificationCode-input{
  width: 180px;
}
.VerificationCode{
  display: inline-block;
  vertical-align: bottom;
  margin-left: 20px;
  background-color: #fff;
}
.VerificationCode img{
  width: 100%;
  height: 100%;
}

</style>
